import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.svg";

const navigations = (navigation, onUpdateCurrent, device = "browser") => {
  const active = `bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-bold`;
  const inactive = `text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-bold`;
  const activeMobile =
    "bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-bold";

  const inactiveMobile =
    "text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-bold";

  return navigation.map(({ key, name, href, current }) => {
    return (
      <Link
        key={key}
        to={href}
        className={
          device === "browser"
            ? current
              ? active
              : inactive
            : current
            ? activeMobile
            : inactiveMobile
        }
        onClick={onUpdateCurrent.bind(this, key)}
      >
        {name}
      </Link>
    );
  });
};

const Navbar = ({ navigation, onUpdateCurrent, onLogoClicked }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const toggle = () => {
    setIsMenuOpen((prev) => !prev);
  };

  return (
    <nav className="bg-gray-800">
      <div className="px-4 mx-auto max-w-8xl sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <img
                className="w-8 cursor-pointer"
                src={logo}
                alt="logo"
                onClick={onLogoClicked}
              />
            </div>
            <div className="hidden md:block">
              <div className="flex items-baseline ml-10 space-x-4">
                {navigations(navigation, onUpdateCurrent)}
              </div>
            </div>
          </div>
          <div className="flex -mr-2 md:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 text-gray-400 bg-gray-800 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
              onClick={toggle}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className={`${isMenuOpen ? "hidden" : "block"} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                className={`${isMenuOpen ? "block" : "hidden"} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <div className="md:hidden" id="mobile-menu">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {navigations(navigation, onUpdateCurrent, "mobile")}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
