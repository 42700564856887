import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthContextProvider } from "./contexts/authContext";
import { BrowserRouter } from "react-router-dom";
import { SpinnerContextProvider } from "./contexts/spinnerContext";
import { ToastProvider } from "./contexts/toastContext";

ReactDOM.render(
  <React.StrictMode>
    <SpinnerContextProvider>
      <ToastProvider>
        <AuthContextProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AuthContextProvider>
      </ToastProvider>
    </SpinnerContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
