import { useCallback, useContext, useEffect, useState } from "react";
import Login from "./pages/Login";
import Navbar from "./components/Navbar";
import AuthContext from "./contexts/authContext";
import useFetch from "./hooks/useFetch";
import FoodsPage from "./pages/Foods";
import FoodForm from "./pages/FoodForm";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Signup from "./pages/Signup";
import NotFound from "./pages/NotFound";
import History from "./pages/History";
import SpinnerContext from "./contexts/spinnerContext";
import ToastContext from "./contexts/toastContext";

function App() {
  const authContext = useContext(AuthContext);
  const { isLoggedIn } = authContext;
  const [navigation, setNavigation] = useState([
    { key: 1, name: "Dashboard", href: "/dashboard", current: false },
    { key: 2, name: "Foods", href: "/foods", current: false },
    { key: 3, name: "History", href: "/history", current: false },
    { key: 4, name: "Sign Out", href: "/login", current: false },
  ]);
  const [foods, setFoods] = useState([]);
  const [records, setRecords] = useState([]);
  const [metrics, setMetrics] = useState();
  const location = useLocation();
  const history = useHistory();
  const { pathname } = location;
  const currentNav = navigation.filter(({ href }) =>
    pathname.startsWith(href)
  )[0]?.key;
  const { logout } = authContext;
  const spinner = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const whileFetching = () => {
    spinner.show();
  };
  const whenFinished = (message, status) => {
    spinner.hide();
  };

  const { response: foodFetchResponse, sendData: getFoods } = useFetch(
    whileFetching,
    whenFinished
  );

  const { response: recordFetchResponse, sendData: getRecords } = useFetch(
    whileFetching,
    whenFinished
  );

  const { response: metricsFetchResponse, sendData: getMetrics } = useFetch(
    whileFetching,
    whenFinished
  );

  const { response: recordAddResponse, sendData: addRecord } = useFetch(
    whileFetching,
    whenFinished
  );

  const { response: metricAddResponse, sendData: addMetric } = useFetch(
    whileFetching,
    whenFinished
  );

  useEffect(() => {
    if (recordAddResponse) {
      setRecords((prev) => [
        ...prev,
        {
          ...recordAddResponse,
          foodId: foods.find(({ _id }) => _id === recordAddResponse.foodId),
        },
      ]);
    }
  }, [recordAddResponse]);

  useEffect(() => {
    if (metricAddResponse) {
      setMetrics((prev) => [
        ...prev,
        {
          ...metricAddResponse,
        },
      ]);
    }
  }, [metricAddResponse]);

  const { response: recordDeleteResponse, sendData: deleteRecord } = useFetch(
    whileFetching,
    whenFinished
  );

  useEffect(() => {
    if (recordDeleteResponse) {
      setRecords((prev) =>
        prev.filter(({ _id }) => _id !== recordDeleteResponse._id)
      );
    }
  }, [recordDeleteResponse]);

  const handleNavChange = useCallback(
    (key) => {
      if (key === 4) logout();
      setNavigation((prev) => {
        const newNavigation = [...prev];
        return newNavigation.map((nav) =>
          nav.key === key
            ? { ...nav, current: true }
            : { ...nav, current: false }
        );
      });
    },
    [logout]
  );

  useEffect(() => {
    if (currentNav) {
      handleNavChange(currentNav);
    }
  }, [handleNavChange, currentNav]);

  useEffect(() => {
    if (isLoggedIn) {
      getFoods("/users/loggedIn/foods?all=true", "GET");
      getMetrics("/users/loggedin/metrics", "GET");
      getRecords("/users/loggedin/records", "GET");
    }
  }, [getFoods, getRecords, getMetrics, isLoggedIn]);

  useEffect(() => {
    if (Array.isArray(foodFetchResponse)) {
      setFoods(foodFetchResponse);
    }
  }, [foodFetchResponse]);

  useEffect(() => {
    if (Array.isArray(recordFetchResponse)) {
      setRecords(recordFetchResponse);
    }
  }, [recordFetchResponse]);

  useEffect(() => {
    if (Array.isArray(metricsFetchResponse)) {
      setMetrics(metricsFetchResponse);
    }
  }, [metricsFetchResponse]);

  const handleAddFood = (food) => {
    setFoods((previous) => {
      const newFoods = [food, ...previous];
      return newFoods;
    });
    toastContext.show(`${food.name} is added successfully!`, "success");
    history.push("/foods");
  };

  const handleUpdateFood = (foodToUpdate) => {
    setFoods((previous) => {
      return previous.map((food) =>
        foodToUpdate._id === food._id ? { ...food, ...foodToUpdate } : food
      );
    });
    if (Array.isArray(records))
      setRecords((prev) => {
        return prev.map((rec) => {
          if (rec.foodId?._id === foodToUpdate._id)
            return { ...rec, foodId: foodToUpdate };
          return rec;
        });
      });
    toastContext.show(
      `${foodToUpdate.name} is updated successfully!`,
      "success"
    );
    history.push("/foods");
  };

  const handleDeleteFood = (foodId) => {
    let foodToDelete;
    setFoods((previous) => {
      return previous.filter((food) => {
        if (food._id === foodId) {
          foodToDelete = food;
        }
        return food._id !== foodId;
      });
    });
    toastContext.show(`${foodToDelete.name} has been deleted!`, "info");
  };

  const handleAddRecord = ({ date, amount, foodId }) => {
    addRecord("/users/loggedin/records", "POST", {
      date,
      amount,
      foodId: foodId._id,
    });
  };

  const handleDeleteRecord = ({ _id }) =>
    deleteRecord(`/users/loggedin/records/${_id}`, "DELETE");

  const handleAddMetric = (weight) => {
    addMetric("/users/loggedin/metrics", "POST", {
      weight,
      date: new Date().toLocaleDateString("en-CA"),
    });
    toastContext.show(`Today weight has been added successfully!`, "success");
  };

  let content = (
    <>
      <Navbar
        navigation={navigation}
        onUpdateCurrent={handleNavChange}
        onLogoClicked={() => history.push("/")}
      />
      <Switch>
        <Route path="/" exact>
          <Redirect to="/dashboard" />
        </Route>
        <Route path="/dashboard">
          <Dashboard
            metrics={metrics}
            records={records}
            foods={foods}
            onAddMetric={handleAddMetric}
          />
        </Route>
        <Route path="/foods" exact>
          <FoodsPage
            onFoodUpdate={handleUpdateFood}
            foods={foods}
            onDeleteFood={handleDeleteFood}
          />
        </Route>
        <Route path="/history">
          <History
            foods={foods}
            records={records}
            onAddRecord={handleAddRecord}
            onDeleteRecord={handleDeleteRecord}
          />
        </Route>
        <Route path="/foods/food-form">
          <FoodForm
            onFoodAdded={handleAddFood}
            onFoodUpdate={handleUpdateFood}
          />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/signup">
          <Signup />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </>
  );

  if (!authContext.isLoggedIn) {
    content = (
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/signup">
          <Signup />
        </Route>
        <Route path="*">
          <Login />
        </Route>
      </Switch>
    );
  }

  return content;
}

export default App;
