export const seperateCamelCase = (string = "") => {
  return string.replace(/([a-z0-9])([A-Z])/g, "$1 $2");
};

export const contains = (string = "", section = "") => {
  return string.trim().toLowerCase().includes(section.trim().toLowerCase());
};

export const isEqualDates = (date1, date2) => {
  return (
    new Date(date1).toLocaleDateString() ===
    new Date(date2).toLocaleDateString()
  );
};

export const groupBy = (data = [], key = "", transform = (value) => value) => {
  return data.reduce((curr, next) => {
    const transformedKey = transform ? transform(next[key]) : next[key];
    if (!curr[transformedKey]) curr[transformedKey] = [];
    curr[transformedKey].push(next);
    return curr;
  }, {});
};

export const getShortNumberPresentation = (number) => {
  return Number.isInteger(number)
    ? number
    : Number.isFinite(number)
    ? parseFloat(number).toFixed(2)
    : null;
};

// H: height, W: weight, A: age, G: gender

export const BMI = (H, W) => (W * 10000) / (H * H);

export const BMR = (H, W, A, G) =>
  10 * W + 6.25 * H - 5 * A + (G === "M" ? 5 : -161);

export const kgToCalories = (kg) => (kg / 0.45) * 3500;

export const getAge = (dateOfBirth) => {
  const today = new Date();
  const birthDate = new Date(dateOfBirth);
  let yearsDifference = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();
  const daysDifference = today.getDate() < birthDate.getDate();
  if (monthDifference < 0 || (monthDifference === 0 && daysDifference < 0))
    yearsDifference--;

  return yearsDifference;
};

export const deviationPercentage = (currentMetric, base) =>
  (currentMetric / base) * 100;

export const BMIClass = (BMI) => {
  if (BMI < 18.5) return "Underweight";
  else if (BMI >= 18.5 && BMI < 25) return "Normal";
  else if (BMI >= 25 && BMI < 30) return "Overweight";
  else if (BMI >= 30 && BMI < 35) return "Obese I";
  else if (BMI >= 35 && BMI < 40) return "Obese II";
  else if (BMI >= 40) return "Obese III";
};

export const getRecordCalories = (record = { amount: Number }, key) => {
  let factor = 0;
  const food = record.foodId;
  if (food) {
    const { units } = food;
    factor = food[key] / units;
  }
  return record.amount * factor;
};

const reduceRecords = (records, key) => {
  return records.reduce((curr, next) => curr + getRecordCalories(next, key), 0);
};

export const getTotalCalories = (records = []) => {
  return reduceRecords(records, "calories");
};

export const getTotalCarbs = (records = []) => {
  return reduceRecords(records, "carbs");
};

export const getTotalProtein = (records = []) => {
  return reduceRecords(records, "protein");
};

export const getTotalFat = (records = []) => {
  return reduceRecords(records, "fat");
};

export const weeklyCalorieConsumptionData = (
  records = [],
  recommendedCalories = 1583.75,
  recommendedCarbs = 212.45,
  recommendedProtein = 48,
  recommendedFat = 45
) => {
  const CHART_COLORS = {
    red: "rgb(255, 99, 132)",
    orange: "rgb(255, 159, 64)",
    yellow: "rgb(255, 205, 86)",
    green: "rgb(75, 192, 192)",
    blue: "rgb(54, 162, 235)",
    purple: "rgb(153, 102, 255)",
    grey: "rgb(201, 203, 207)",
  };

  const transparentize = (color, opacity = 0.2) =>
    CHART_COLORS[color]
      ? CHART_COLORS[color].slice(0, -1).concat(`, ${opacity})`)
      : "";

  const descDateSorter = (a, b) => {
    return new Date(b).getTime() - new Date(a).getTime();
  };

  const recordsAmongEachDay = groupBy(records, "date", (val) =>
    new Date(val).toLocaleDateString()
  );

  const labels = Object.keys(recordsAmongEachDay)
    .sort(descDateSorter)
    .filter((_, i) => i < 7)
    .reverse();

  const lastSevenDays = () => {
    return Array.from({ length: 7 }, (_, index) => {
      const date = new Date();
      date.setDate(new Date().getDate() - index);
      return date;
    }).map((date) => date.toLocaleDateString());
  };

  const getDayPresentation = (label) => {
    const currentDate = new Date();
    const today = currentDate.toLocaleDateString();
    currentDate.setDate(currentDate.getDate() - 1);
    const yesterday = currentDate.toLocaleDateString();
    if (label === today) return "Today";
    else if (label === yesterday) return "Yesterday";
    else
      return new Date(label).toLocaleDateString("en-US", { weekday: "long" });
  };
  const cleanedLabels = labels.map((label) =>
    lastSevenDays().includes(label) ? getDayPresentation(label) : label
  );

  return {
    labels: cleanedLabels,
    datasets: [
      {
        label: "Calories Consumption",
        data: labels.map(
          (date) =>
            (getTotalCalories(recordsAmongEachDay[date]) /
              recommendedCalories) *
            100
        ),
        fill: true,
        backgroundColor: transparentize("green"),
        borderColor: CHART_COLORS.green,
      },
      {
        label: "Carbs Consumption",
        data: labels.map(
          (date) =>
            (getTotalCarbs(recordsAmongEachDay[date]) / recommendedCarbs) * 100
        ),
        fill: true,
        backgroundColor: transparentize("purple"),
        borderColor: CHART_COLORS.purple,
      },
      {
        label: "Protein Consumption",
        data: labels.map(
          (date) =>
            (getTotalProtein(recordsAmongEachDay[date]) / recommendedProtein) *
            100
        ),
        fill: true,
        backgroundColor: transparentize("blue"),
        borderColor: CHART_COLORS.blue,
      },
      {
        label: "Fat Consumption",
        data: labels.map(
          (date) =>
            (getTotalFat(recordsAmongEachDay[date]) / recommendedFat) * 100
        ),
        fill: true,
        backgroundColor: transparentize("red"),
        borderColor: CHART_COLORS.red,
      },
      {
        label: "Recommended Calories Consumption",
        data: Array(labels.length).fill(100),
        fill: false,
        borderColor: CHART_COLORS.yellow,
      },
    ],
  };
};

export const weeklyWeightData = (metrics = [], goalWeight = 60) => {
  const CHART_COLORS = {
    red: "rgb(255, 99, 132)",
    orange: "rgb(255, 159, 64)",
    yellow: "rgb(255, 205, 86)",
    green: "rgb(75, 192, 192)",
    blue: "rgb(54, 162, 235)",
    purple: "rgb(153, 102, 255)",
    grey: "rgb(201, 203, 207)",
  };

  const transparentize = (color, opacity = 0.2) =>
    CHART_COLORS[color]
      ? CHART_COLORS[color].slice(0, -1).concat(`, ${opacity})`)
      : "";

  const descDateSorter = (a, b) => {
    return new Date(b).getTime() - new Date(a).getTime();
  };

  const metricsAmongEachDay = groupBy(metrics, "date", (val) =>
    new Date(val).toLocaleDateString()
  );
  const cleanedMetricsAmongEachDay = {};
  Object.keys(metricsAmongEachDay).forEach((date) => {
    const weightMetrics = metricsAmongEachDay[date].filter(
      (val) => val["weight"]
    );
    if (weightMetrics.length > 0) {
      cleanedMetricsAmongEachDay[date] = weightMetrics[0].weight;
    }
  });

  const labels = Object.keys(cleanedMetricsAmongEachDay)
    .sort(descDateSorter)
    .filter((_, i) => i < 7)
    .reverse();

  const lastSevenDays = () => {
    return Array.from({ length: 7 }, (_, index) => {
      const date = new Date();
      date.setDate(new Date().getDate() - index);
      return date;
    }).map((date) => date.toLocaleDateString());
  };

  const getDayPresentaion = (label) => {
    const currentDate = new Date();
    const today = currentDate.toLocaleDateString();
    currentDate.setDate(currentDate.getDate() - 1);
    const yesterday = currentDate.toLocaleDateString();
    if (label === today) return "Today";
    else if (label === yesterday) return "Yesterday";
    else
      return new Date(label).toLocaleDateString("en-US", { weekday: "long" });
  };
  const cleanedLabels = labels.map((label) =>
    lastSevenDays().includes(label) ? getDayPresentaion(label) : label
  );

  return {
    labels: cleanedLabels,
    datasets: [
      {
        label: "Measured Weight",
        data: labels.map((date) => cleanedMetricsAmongEachDay[date]),
        fill: true,
        backgroundColor: transparentize("green"),
        borderColor: CHART_COLORS.green,
      },
      {
        label: "Goal Weight",
        data: Array(labels.length).fill(goalWeight),
        fill: false,
        borderColor: CHART_COLORS.red,
      },
    ],
  };
};
