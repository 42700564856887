import salad from "../assets/salad.svg";
import Header from "../components/Header";
import CardsContainer from "../components/CardsContainer";
import Pagination from "../ui/Pagination";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import SpinnerContext from "../contexts/spinnerContext";
import compress from "../api/compress";
import { contains } from "../utilities/functions";

const PAGE_SIZE = 12;

const transformProps = (props) => {
  let newProps;
  if (props.measurement === "gm" || props.measurement === "ml") {
    const { units, calories, protein, carbs, fat } = props;
    const factor = 100 / units;
    newProps = {
      ...props,
      units: units * factor,
      calories: calories * factor,
      protein: protein * factor,
      carbs: carbs * factor,
      fat: fat * factor,
    };
  } else newProps = { ...props };
  for (const key in newProps) {
    if (Number.isInteger(newProps[key])) {
    } else if (Number.isFinite(newProps[key])) {
      newProps[key] = parseFloat(newProps[key]).toFixed(2);
    }
  }
  return newProps;
};

const Food = (props) => {
  const { onDeleteFood, onUpdateFood, onUpdateFoodImage } = props;
  const {
    _id,
    name,
    measurement,
    units,
    calories,
    protein,
    carbs,
    fat,
    image,
  } = transformProps(props);
  const spinner = useContext(SpinnerContext);
  const whileFetching = () => {
    spinner.show();
  };

  const whenFinished = (message, status) => {
    spinner.hide();
    if (status === 200) {
      onDeleteFood(_id);
    }
  };

  const { sendData } = useFetch(whileFetching, whenFinished);

  const handleDelete = (e) => {
    sendData("/users/loggedIn/foods/" + _id, "DELETE");
  };

  return (
    <div key={_id} className="w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4 p-4">
      <div className="block bg-white shadow-md hover:shadow-xl rounded-lg overflow-hidden">
        <div className="relative pb-16 md:pb-48 overflow-hidden m-2">
          <label htmlFor={_id}>
            <img
              className="absolute inset-0 h-full w-full object-contain"
              src={(image !== "null" && image) || salad}
              alt="food"
            />
          </label>
          <input
            className="hidden"
            onChange={onUpdateFoodImage}
            id={_id}
            type="file"
          />
        </div>
        <div className="p-4">
          <span className="uppercase inline-block px-2 py-1 leading-none bg-gray-200 text-gray-800 rounded-full font-semibold tracking-wide text-xs">
            {calories} calories / {units} {measurement}
          </span>
          <h2 className="capitalize mt-2 mb-2  font-bold">{name}</h2>
        </div>
        <div className="p-2 border-t border-b text-sm text-gray-700">
          <span className="flex items-center mb-1">
            <i className="far fa-clock fa-fw mr-2 text-gray-900" />{" "}
            Carbohydrates: {carbs}
          </span>
        </div>
        <div className="p-2 border-b flex items-center text-sm text-gray-600">
          <span className="ml-2">Fat: {fat}</span>
        </div>
        <div className="p-2 border-b flex items-center text-sm text-gray-600">
          <span className="ml-2">Protein: {protein}</span>
        </div>
        <div className="p-2 flex items-center justify-between text-sm text-gray-600">
          <span
            className="cursor-pointer px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
            name="{name}"
            onClick={onUpdateFood}
          >
            Update
          </span>
          <span
            className="cursor-pointer px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-green-800"
            onClick={handleDelete}
          >
            Delete
          </span>
        </div>
      </div>
    </div>
  );
};

const FoodPage = ({ foods, onDeleteFood, onFoodUpdate }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentFoods, setCurrentFoods] = useState(foods || []);
  const [search, setSearch] = useState("");

  const history = useHistory();
  const location = useLocation();
  const spinner = useContext(SpinnerContext);
  const { response, sendData } = useFetch(spinner.show, spinner.hide);

  const sliceFoods = (foods) => {
    const offset = currentPage - 1;
    const start = offset * PAGE_SIZE;
    return foods.slice(start, start + PAGE_SIZE);
  };

  useEffect(() => {
    setCurrentFoods(foods.filter((food) => contains(food.name, search)));
  }, [search]);

  useEffect(() => {
    if (foods) {
      setCurrentFoods(foods);
    }
  }, [foods]);

  useEffect(() => {
    if (response) onFoodUpdate(response);
  }, [response]);

  const handleUpdateFoodImage = (_id) => (e) => {
    const { files } = e.target;
    if (files.length > 0) {
      compress(files[0], (compressedFile) => {
        const formData = new FormData();
        formData.append("image", compressedFile);
        sendData("/users/loggedIn/foods/" + _id, "PUT", formData, {
          "Content-Type": "multipart/form-data",
        });
      });
    }
  };

  const handleFoodSearch = (e) => {
    setCurrentPage(1);
    setSearch(e.target.value);
  };

  return (
    <>
      <Header name="Foods">
        <button
          className="py-2 px-4 border border-transparent shadow-sm text-md font-bold rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() =>
            history.push(location.pathname + "/food-form?operation=add")
          }
        >
          Add Food
        </button>
      </Header>
      <form method="POST" id="food-form" className="m-5">
        <div className="shadow overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 bg-white sm:p-6">
            <div id="form-inputs">
              <div className="col-span-6">
                <label
                  htmlFor="food-name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Search For Food
                </label>
                <input
                  type="search"
                  placeholder="Enter food name ..."
                  name="food"
                  id="food-name"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  onChange={handleFoodSearch}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      {currentFoods.length === 0 && (
        <h2 className="shadow overflow-hidden sm:rounded-md m-5 px-4 py-5 bg-white sm:p-6 text-lg font-bold">
          No foods found.
        </h2>
      )}
      <CardsContainer>
        {sliceFoods(currentFoods).map((food) => (
          <Food
            key={food._id}
            {...food}
            onDeleteFood={onDeleteFood}
            onUpdateFood={() => {
              history.push(
                location.pathname + "/food-form?operation=update",
                food
              );
            }}
            onUpdateFoodImage={handleUpdateFoodImage(food._id)}
          />
        ))}
      </CardsContainer>
      {currentFoods.length !== 0 && (
        <Pagination
          totalCount={currentFoods.length}
          pageSize={PAGE_SIZE}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      )}
    </>
  );
};

export default FoodPage;
