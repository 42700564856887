const Header = ({ name, children }) => {
  return (
    <header className="bg-white shadow">
      <div className="max-w-8xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex flex-wrap justify-between align-middle">
        <h2 className="capitalize text-3xl font-bold text-gray-900">{name}</h2>
        {children}
      </div>
    </header>
  );
};

export default Header;
