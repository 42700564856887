import React, { useCallback, useEffect, useState } from "react";
import Toast from "../ui/Toast";
import reactDom from "react-dom";

const ToastContext = React.createContext({
  show: (message, type) => {},
});

export const ToastProvider = ({ children, timeout = 4000 }) => {
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");

  useEffect(() => {
    if (visible) {
      const timer = setTimeout(() => {
        setVisible(false);
      }, timeout);

      return function cleanUp() {
        clearTimeout(timer);
      };
    }
  }, [timeout, visible]);

  const show = useCallback((m, t) => {
    setMessage(m);
    setType(t);
    setVisible(true);
  }, []);

  const context = { show, visible, message, type };

  return (
    <ToastContext.Provider value={context}>
      <ToastContext.Consumer>
        {(ctx) => (
          <>
            {ctx.visible &&
              reactDom.createPortal(
                <Toast type={type} message={message} />,
                document.getElementById("toast")
              )}
            {children}
          </>
        )}
      </ToastContext.Consumer>
    </ToastContext.Provider>
  );
};

export default ToastContext;
