const Spinner = ({ hidden, height }) => {
  return (
    <div
      className={`${hidden && "hidden"} absolute inset-0 bg-gray-200 z-50`}
      style={{ height }}
    >
      <div
        style={{
          top: "calc(50vh - calc(128px / 2))",
          left: "calc(50vw - calc(128px / 2))",
        }}
        className="fixed animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"
      ></div>
    </div>
  );
};

export default Spinner;
