import { useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import Header from "../components/Header";
import Input from "../components/Input";
import { signupForm, required } from "../utilities/forms";
import SpinnerContext from "../contexts/spinnerContext";
import ToastContext from "../contexts/toastContext";
import useFetch from "../hooks/useFetch";
import useForm from "../hooks/useForm";

const Signup = () => {
  const history = useHistory();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const whileFetching = () => {
    spinnerContext.show();
  };

  const whenFinished = (message, status) => {
    spinnerContext.hide();
    if (status === 201) {
      toastContext.show(message, "success");
      history.push("/login");
    } else if (status === 409) {
      toastContext.show(message, "error");
    }
  };

  const { sendData } = useFetch(whileFetching, whenFinished);

  const { handleSubmit, handleChange, data, errors } = useForm({
    validations: {
      ...signupForm.validations,
      confirmPassword: {
        required,
        custom: {
          isValid: (value) => value === data.password,
          message: "Passwords doesn't match.",
        },
      },
    },
    initialValues: signupForm.initialValues,
    onSubmit: () => {
      sendData("/users/signup", "POST", data);
    },
  });

  return (
    <>
      <Header name="Signup" />
      <div className="mt-0 md:my-10">
        <div className="md:grid md:grid-cols-6 md:gap-6">
          <div className="md:col-span-1" />
          <div className="mt-5 md:mt-0 md:col-span-4">
            <form onSubmit={handleSubmit}>
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6" id="form-inputs">
                    {Object.entries(signupForm.inputs).map(
                      ([field, config], index) => (
                        <Input
                          key={field}
                          field={field}
                          data={data}
                          errors={errors}
                          onChange={handleChange(field, config.transformFN)}
                          {...config}
                          focused={index === 0}
                        />
                      )
                    )}
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button
                    className="capitalize inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    id="submit-button"
                    type="submit"
                  >
                    Signup
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="md:col-span-1" />
        </div>
      </div>
    </>
  );
};
export default Signup;
