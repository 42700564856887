import InputError from "./InputError";
import {
  INPUT_CLASSES,
  SPACE,
  ERROR_CLASS,
  LABEL_CLASSES,
} from "../utilities/forms";
import { seperateCamelCase } from "../utilities/functions";
import { useEffect, useRef } from "react";

const Input = ({ field, type, data, onChange, errors, focused, ...rest }) => {
  const ref = useRef();
  const className = INPUT_CLASSES + (errors[field] ? SPACE + ERROR_CLASS : "");
  const value = data[field] === 0 ? 0 : data[field] || "";
  let extra = {};
  if (rest.max) extra.max = rest.max;

  useEffect(() => {
    if (ref.current) ref.current.focus();
  }, []);

  return (
    <div className="col-span-6">
      <label htmlFor={field} className={LABEL_CLASSES}>
        {seperateCamelCase(field)}
      </label>
      <input
        type={type}
        name={field}
        id={field}
        value={value}
        className={className}
        onChange={onChange}
        ref={focused ? ref : null}
        {...extra}
      />
      <InputError field={field} errors={errors} />
    </div>
  );
};

export default Input;
