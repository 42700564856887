import { useEffect, useState } from "react";
import Header from "../components/Header";
import { INPUT_CLASSES, SPACE } from "../utilities/forms";
import salad from "../assets/salad.svg";

import {
  contains,
  getRecordCalories,
  getShortNumberPresentation,
  getTotalCalories,
  getTotalCarbs,
  getTotalFat,
  getTotalProtein,
  isEqualDates,
} from "../utilities/functions";

const History = ({
  foods = [],
  records = [],
  onAddRecord = (record) => {},
  onDeleteRecord = (record) => {},
}) => {
  const [presentFoods, setPresentFoods] = useState([]);
  const [search, setSearch] = useState("");
  const [amount, setAmount] = useState("");
  const [selectedFood, setSelectedFood] = useState();
  const [eatenDate, setEatenDate] = useState(
    new Date().toLocaleDateString("en-CA")
  );
  const [recordDate, setRecordDate] = useState(
    new Date().toLocaleDateString("en-CA")
  );

  useEffect(() => {
    if (foods)
      setPresentFoods(
        foods
          .filter(({ name }) => contains(name, search))
          .filter((_, i) => i < 5)
      );
  }, [search]);

  const rowColor = (i) => (i % 2 ? "bg-white" : "bg-blue-200");
  // mobile
  // const mod = i % 4; return mod === 1 || mod === 2 ? "bg-white" : "bg-blue-200";

  const handleFoodChosen = (food) => {
    setSearch(food.name);
    setSelectedFood(food);
  };

  return (
    <>
      <Header name="History" />
      <form method="POST" id="food-form" className="m-5">
        <div className="shadow overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 bg-white sm:p-6">
            <div id="form-inputs">
              <div className="col-span-6">
                <label
                  htmlFor="food-name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Food Name
                </label>
                <input
                  type="search"
                  placeholder="Type something ..."
                  name="food"
                  id="food-name"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <div className="col-span-6 my-2 flex flex-wrap" id="food-badges">
                {search === "" &&
                  foods &&
                  foods
                    .filter((_, i) => i < 5)
                    .map((food) => (
                      <div
                        key={food._id}
                        className="capitalize text-sm cursor-pointer m-2 font-semibold  py-3 px-5 rounded-full text-green-600 bg-gray-200 last:mr-0 mr-1 flex items-center"
                        onClick={handleFoodChosen.bind(this, food)}
                      >
                        <img
                          src={(food.image !== "null" && food.image) || salad}
                          alt={food.name}
                          className="mr-2 w-8"
                        />
                        <span key={food._id}>{food.name}</span>
                      </div>
                    ))}
                {search !== "" &&
                  presentFoods.map((food) => (
                    <div
                      key={food._id}
                      className="capitalize text-sm cursor-pointer m-2 font-semibold  py-3 px-5 rounded-full text-green-600 bg-gray-200 last:mr-0 mr-1 flex items-center"
                      onClick={handleFoodChosen.bind(this, food)}
                    >
                      <img
                        src={(food.image !== "null" && food.image) || salad}
                        alt={food.name}
                        className="mr-2 w-8"
                      />
                      <span>{food.name}</span>
                    </div>
                  ))}
              </div>
              <div className="col-span-6">
                <label
                  htmlFor="amount"
                  className="block text-sm font-medium text-gray-700"
                >
                  Amount{" "}
                  {selectedFood && (
                    <>
                      <sup className="uppercase">
                        <span className="text-green-400 font-bold text-sm">
                          {selectedFood.measurement}
                        </span>
                      </sup>

                      <sup className="uppercase">
                        <span className="text-yellow-400 font-bold text-sm">
                          {" "}
                          ({selectedFood.units} per Serving)
                        </span>
                      </sup>
                    </>
                  )}
                </label>
                <input
                  type="number"
                  name="amount"
                  id="amount"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  min={0}
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              <div className="col-span-6 mt-4">
                <label
                  htmlFor="date"
                  className="block text-sm font-medium text-gray-700"
                >
                  Date
                </label>
                <input
                  type="date"
                  value={eatenDate}
                  name="date"
                  id="date"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  max={new Date().toLocaleDateString("en-CA")}
                  onChange={(e) =>
                    setEatenDate(
                      new Date(e.target.value).toLocaleDateString("en-CA")
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              type="button"
              onClick={() => {
                const foodId = foods.find(({ name }) => name === search);
                if (foodId) {
                  onAddRecord({
                    amount,
                    date: eatenDate,
                    search,
                    foodId,
                  });
                } else throw Error("Implement this case");
              }}
            >
              Add
            </button>
          </div>
        </div>
      </form>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg m-5">
        <div className="px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            History
          </h3>
          <input
            type="date"
            className={INPUT_CLASSES + SPACE + "sm:mt-0 sm:col-span-1"}
            value={recordDate}
            onChange={(e) =>
              setRecordDate(
                new Date(e.target.value).toLocaleDateString("en-CA")
              )
            }
          />
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="px-4 py-5 text-center sm:px-6 flex items-center justify-around flex-wrap">
              <span className="font-medium rounded-full bg-green-100 text-green-800 p-3 my-2">
                Total Calories:{" "}
                {0 ||
                  (records &&
                    getShortNumberPresentation(
                      getTotalCalories(
                        records.filter(({ date }) =>
                          isEqualDates(recordDate, date)
                        )
                      )
                    ))}
              </span>
              <span className="font-medium rounded-full bg-blue-100 text-blue-800 p-3 my-2">
                Total Carbs:{" "}
                {0 ||
                  (records &&
                    getShortNumberPresentation(
                      getTotalCarbs(
                        records.filter(({ date }) =>
                          isEqualDates(recordDate, date)
                        )
                      )
                    ))}
              </span>
              <span className="font-medium rounded-full bg-yellow-100 text-yellow-800 p-3 my-2">
                Total Protein:{" "}
                {0 ||
                  (records &&
                    getShortNumberPresentation(
                      getTotalProtein(
                        records.filter(({ date }) =>
                          isEqualDates(recordDate, date)
                        )
                      )
                    ))}
              </span>
              <span className="font-medium rounded-full bg-indigo-100 text-indigo-800 p-3 my-2">
                Total Fat:{" "}
                {0 ||
                  (records &&
                    getShortNumberPresentation(
                      getTotalFat(
                        records.filter(({ date }) =>
                          isEqualDates(recordDate, date)
                        )
                      )
                    ))}
              </span>
            </div>
            <div className="grid  grid-cols-2 sm:block">
              {records &&
                records
                  .filter(({ date }) => isEqualDates(recordDate, date))
                  .map((record, i) => {
                    return (
                      <div
                        className={
                          rowColor(i) +
                          SPACE +
                          "px-4 py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6 items-center"
                        }
                        key={record._id}
                      >
                        <dt className="font-medium text-gray-500 flex items-center">
                          <img
                            src={
                              (record.foodId.image !== "null" &&
                                record.foodId.image) ||
                              salad
                            }
                            alt={record.foodId ? record.foodId.name : "UNKNOWN"}
                            style={{ width: "32px" }}
                          />
                          <span className="ml-2 capitalize">
                            {record.foodId ? record.foodId.name : "UNKNOWN"}
                          </span>
                        </dt>
                        <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-1">
                          {record.amount} {record.foodId.measurement}
                        </dd>
                        <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-1">
                          {getShortNumberPresentation(
                            getRecordCalories(record, "calories")
                          )}{" "}
                          calorie
                        </dd>
                        <div className="sm:col-span-1 ">
                          <span
                            className="food-list-item-delete cursor-pointer px-2 inline-flex text-sm leading-5 font-semibold rounded-full bg-red-100 text-green-800"
                            onClick={onDeleteRecord.bind(this, record)}
                          >
                            Delete
                          </span>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </dl>
        </div>
      </div>
    </>
  );
};

export default History;
