import { useCallback, useState } from "react";
import axios from "../api/axios-instance";

const useFetch = (
  whileFetching = () => {},
  whenFinished = (message, status, response) => {}
) => {
  const [response, setResponse] = useState(null);

  const sendData = useCallback(
    async (url = "/", method = "GET", payloadData, headers) => {
      try {
        whileFetching();
        const payload = { method, url };
        payload.data = payloadData;
        if (headers) payload.headers = headers;
        const data = await axios.request(payload);
        setResponse(data.data);
        whenFinished(
          data.data.message || "Successfully Fetched Data",
          data.status,
          data.data
        );
      } catch (error) {
        const { response } = error;
        const responseData = response?.data;
        whenFinished(
          responseData?.error?.message ||
            responseData?.message ||
            response?.statusText,
          response?.status,
          response
        );
      }
    },
    []
  );

  return { response, sendData };
};

export default useFetch;
