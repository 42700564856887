import axios from "./axios-instance";

const api = {};
const login = (payload) => {
  return axios.post("/users/login", {
    ...payload,
  });
};

api.login = login;

export default api;
