import usePagination from "../hooks/usePagination";

const PaginationBlock = ({ number, onPageChange, current }) => {
  const className = current
    ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600"
    : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50";
  return number === "..." ? (
    <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
      ...
    </span>
  ) : (
    <nav
      aria-current="page"
      className={`${className} cursor-pointer relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
      onClick={() => onPageChange(number)}
    >
      {number}
    </nav>
  );
};

const Pagination = ({ totalCount, currentPage, pageSize, onPageChange }) => {
  const { paginationRanges, firstPageIndex, lastPageIndex } = usePagination(
    totalCount,
    currentPage,
    pageSize
  );

  const onNext = () => {
    currentPage !== lastPageIndex && onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    currentPage !== firstPageIndex && onPageChange(currentPage - 1);
  };

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="flex-1 flex justify-between sm:hidden">
        <nav
          className={`${
            currentPage === firstPageIndex
              ? "cursor-not-allowed"
              : "cursor-pointer"
          } relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50`}
          onClick={onPrevious}
        >
          Previous
        </nav>
        <p className="text-sm items-center px-4 py-2 text-gray-700">
          <span className="font-medium"> {currentPage} </span>/ {lastPageIndex}
        </p>
        <nav
          className={`${
            currentPage === lastPageIndex
              ? "cursor-not-allowed"
              : "cursor-pointer"
          } ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50`}
          onClick={onNext}
        >
          Next
        </nav>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            <span className="font-medium"> {totalCount} </span>
            Results
          </p>
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <div
              className={`${
                currentPage === firstPageIndex
                  ? "cursor-not-allowed"
                  : "cursor-pointer"
              } relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50`}
              onClick={onPrevious}
            >
              <span className="sr-only">Previous</span>
              {/* Heroicon name: solid/chevron-left */}
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            {paginationRanges.map((number, index) => (
              <PaginationBlock
                key={number === "..." ? number + index : number}
                number={number}
                onPageChange={onPageChange}
                current={number === currentPage}
              />
            ))}
            <nav
              className={`${
                currentPage === lastPageIndex
                  ? "cursor-not-allowed"
                  : "cursor-pointer"
              } relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50`}
              onClick={onNext}
            >
              <span className="sr-only">Next</span>
              {/* Heroicon name: solid/chevron-right */}
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </nav>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
