export const INPUT_CLASSES =
  "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md";
export const ERROR_CLASS = "border-red-500";
export const LABEL_CLASSES =
  "capitalize block text-sm font-medium text-gray-700";
export const SPACE = " ";

export const required = {
  value: true,
  message: "This field is required",
};

const inputTypes = {
  integer: { type: "number", transformFN: parseInt },
  float: { type: "number", transformFN: parseFloat },
  text: { type: "text" },
  password: { type: "password" },
  date: { type: "date" },
};

export const foodForm = {
  validations: {
    name: {
      required,
      // pattern: {
      //   value: "^[A-Za-z  ']*$",
      //   message: "Special characters and numbers are not allowed.",
      // },
    },
    calories: {
      required,
      custom: {
        isValid: (value) => value >= 0,
        message: "Calories should be a positive number.",
      },
    },
    protein: {
      required,
      custom: {
        isValid: (value) => value >= 0,
        message: "Protein should be a positive number.",
      },
    },
    carbs: {
      required,
      custom: {
        isValid: (value) => value >= 0,
        message: "Carbs should be a positive number.",
      },
    },
    fat: {
      required,
      custom: {
        isValid: (value) => value >= 0,
        message: "Fat should be a positive number.",
      },
    },
    units: {
      required,
      custom: {
        isValid: (value) => value >= 1,
        message: "Units should be a positive integer.",
      },
    },
    measurement: {
      required,
    },
  },
  initialValues: {
    name: "",
    calories: 0,
    protein: 0,
    carbs: 0,
    fat: 0,
    units: 0,
    measurement: "",
  },
  inputs: {
    name: inputTypes.text,
    calories: inputTypes.float,
    protein: inputTypes.float,
    carbs: inputTypes.float,
    fat: inputTypes.float,
    units: inputTypes.integer,
    measurement: inputTypes.text,
  },
};

export const signupForm = {
  validations: {
    username: {
      required,
      pattern: {
        value: "^[A-Za-z]*$",
        message: "Special characters and numbers are not allowed.",
      },
    },
    email: {
      required,
      pattern: {
        value:
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
        message: "Invalid email address.",
      },
    },
    password: {
      required,
      custom: {
        isValid: (value) => value.length >= 6,
        message: "Password should be at least 6 charachters long.",
      },
    },
    initialWeight: {
      required,
      custom: {
        isValid: (value) => value > 0 && value <= 200,
        message: "Weight can't be negative or bigger than 200kg.",
      },
    },
    goalWeight: {
      required,
      custom: {
        isValid: (value) => value > 0 && value <= 200,
        message: "Weight can't be negative or bigger than 200kg.",
      },
    },
    height: {
      required,
      custom: {
        isValid: (value) => value >= 20 && value <= 300,
        message: "Height must be within 20cm to 300cm.",
      },
    },
    activityLevel: {
      required,
    },
    dateOfBirth: {
      required,
      custom: {
        isValid: (value) => new Date() <= Date.now(),
        message: "Invalid date.",
        // max: new Date().toLocaleDateString("en-US")
        //   it shouldn't be a future date
      },
    },
  },
  initialValues: {
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    initialWeight: 60,
    goalWeight: 60,
    height: 160,
    activityLevel: 1.2,
    dateOfBirth: "2000-01-01",
  },
  inputs: {
    username: inputTypes.text,
    email: inputTypes.text,
    password: inputTypes.password,
    confirmPassword: inputTypes.password,
    initialWeight: inputTypes.float,
    goalWeight: inputTypes.float,
    height: inputTypes.float,
    activityLevel: inputTypes.float,
    dateOfBirth: {
      ...inputTypes.date,
      max: new Date().toLocaleDateString("en-CA"),
    },
  },
};
