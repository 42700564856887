import weight_scale from "../assets/weightScale.svg";
import calories_intake from "../assets/caloriesIntake.png";
import _carbs from "../assets/carbs.png";
import _protein from "../assets/protein.png";
import _fat from "../assets/fat.png";

export const weightScale = weight_scale;
export const caloriesIntake = calories_intake;
export const carbs = _carbs;
export const protein = _protein;
export const fat = _fat;

