import { getShortNumberPresentation } from "../utilities/functions";

const StatCard = ({
  img,
  statistic,
  unit,
  deviationPercentage,
  title,
  description,
}) => {
  return (
    <div className="flex flex-row bg-white shadow-sm rounded p-4">
      <div className="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl">
        <div className="w-12 h-12">
          <img src={img} alt="Weight Scale" />
        </div>
      </div>
      <div className="flex flex-col flex-grow ml-4">
        <div className="text-sm text-gray-500">{title}</div>
        <div className="font-bold text-lg">
          {getShortNumberPresentation(statistic)} {unit}{" "}
        </div>
      </div>
      <span className="text-sm font-bold text-green-500" title={description}>
        {getShortNumberPresentation(deviationPercentage)}%
      </span>
    </div>
  );
};

export default StatCard;
