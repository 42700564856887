import React, { useCallback, useState } from "react";
import reactDom from "react-dom";
import Spinner from "../ui/Spinner";

const SpinnerContext = React.createContext({
  isShown: false,
  show: () => {},
  hide: () => {},
});

export const SpinnerContextProvider = ({ children }) => {
  const [shown, setShown] = useState(false);

  const show = useCallback(() => {
    setShown(true);
  }, []);

  const hide = useCallback(() => {
    setShown(false);
  }, []);

  const context = { isShown: shown, show, hide };

  const currentHeight = () => {
    const body = document.body;
    const html = document.documentElement;
    return Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
  };

  return (
    <SpinnerContext.Provider value={context}>
      <SpinnerContext.Consumer>
        {(ctx) => (
          <>
            {reactDom.createPortal(
              <Spinner hidden={!ctx.isShown} height={currentHeight()} />,
              document.getElementById("spinner")
            )}
            {children}
          </>
        )}
      </SpinnerContext.Consumer>
    </SpinnerContext.Provider>
  );
};

export default SpinnerContext;
