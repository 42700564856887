import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import Header from "../components/Header";
import InputError from "../components/InputError";
import StatCard from "../components/StatCard";
import Modal from "../ui/Modal";

import {
  BMI,
  BMIClass,
  BMR,
  getAge,
  groupBy,
  kgToCalories,
  getTotalCalories,
  getTotalCarbs,
  getTotalProtein,
  getTotalFat,
  weeklyCalorieConsumptionData,
  deviationPercentage,
  weeklyWeightData,
} from "../utilities/functions";
import {
  caloriesIntake,
  weightScale,
  carbs,
  protein,
  fat,
} from "../utilities/images";
const golden = `
You'll see reference intakes referred to on food labels. They show you the maximum amount of calories and nutrients you should eat on average in a day.

Daily reference intakes for adults are:

Energy: 8,400kJ/2,000kcal
Total fat: less than 70g
Saturates: less than 20g
Carbohydrate: 260g
Total sugars: 90g
Protein: 50g
Salt: less than 6g
The reference intake for total sugars includes sugars from milk, fruit and vegetables, as well as added sugar.

See How much sugar is good for me? to learn more about added sugar and the type of sugars most of us should cut down on.

Reference intakes are not meant to be targets. They just give you a rough idea of how much energy you should be eating each day, and how much fat, sugar, salt and so on.

Unless the label says otherwise, reference intakes are based on an average-sized woman doing an average amount of physical activity.

This is to reduce the risk of people with lower energy requirements eating too much, and to make sure information on labels is clear and consistent.

How can I tell if a food is high in fat, saturated fat, sugars or salt?
Looking at the amount of each nutrient in 100g or a portion of a food can give you an idea of how much it contributes to your daily intakes. This information is sometimes also written on the label as a percentage of the reference intake, or % RI.

There are guidelines to tell you if a food is high in fat, saturated fat ("saturates"), sugars or salt, or not:

Fat
High in fat: more than 17.5g of fat per 100g
Low in fat: 3g of fat or less per 100g

Saturated fat (saturates)
High in saturates: more than 5g of saturates per 100g
Low in saturates: 1.5g of saturates or less per 100g

Sugars
High in sugars: more than 22.5g of total sugars per 100g
Low in sugars: 5g of total sugars or less per 100g

Salt
High in salt: more than 1.5g of salt per 100g
Low in salt: 0.3g of salt or less per 100g 

So, for example, if you're trying to cut down on sugar, you should eat fewer foods that have more than 22.5g of sugars per 100g.

If you want to eat less saturated fats, it's best to choose fewer foods that have more than 5g of saturates per 100g.

The "traffic-light" style colour coding on the front of food packs helps you see at a glance whether a food is high (red), medium (amber) or low (green) in fat, saturated fat, sugars or salt.
`;
const Dashboard = ({ metrics, records, foods, onAddMetric }) => {
  const {
    username,
    initialWeight,
    goalWeight,
    height,
    activityLevel,
    dateOfBirth,
  } = JSON.parse(localStorage.getItem("user"));
  const [dailyCaloriesConsumption, setDailyCaloriesConsumption] = useState(0);
  const [dailyCarbsConsumption, setDailyCarbsConsumption] = useState(0);
  const [dailyProteinConsumption, setDailyProteinConsumption] = useState(0);
  const [dailyFatConsumption, setDailyFatConsumption] = useState(0);
  const [data, setData] = useState({});
  const [weightData, setWeightData] = useState({});
  const [visible, setVisible] = useState(false);
  const [currentWeight, setCurrentWeight] = useState(initialWeight);
  const [currentWeightInput, setCurrentWeightInput] = useState(0);
  const [errors, setErrors] = useState(0);
  const bmr = BMR(height, goalWeight, getAge(dateOfBirth), "M");

  useEffect(() => {
    const bmi = BMI(height, currentWeight);
    console.log({
      height,
      currentWeight,
      BMI: bmi,
      BMR: bmr * 1.1,
      "Calories in 1 KG": kgToCalories(1),
      BMIClass: BMIClass(bmi),
    });
    console.log(`
    The value obtained from these equations is the estimated number of calories
    a person can consume in a day to maintain their body-weight,
    assuming they remain at rest. This value is multiplied by an
    activity factor (generally 1.2-1.95),
    dependent on a person's typical levels of exercise,
    `);
    console.log(`0.45 kg, equates to about 3,500 calories`);
    console.log(`
    Mild weight loss 0.25 kg/week
    Weight loss 0.5 kg/week
    Extreme weight loss 1 kg/week`);
    console.log(`300gm loss/ week => 1.2 KG/month`);
    console.log(bmr * activityLevel - kgToCalories(0.25) / 7);
  }, []);

  useEffect(() => {
    if (records) {
      let recordsAmongEachDay = groupBy(records, "date", (val) =>
        new Date(val).toLocaleDateString()
      )[new Date().toLocaleDateString()];
      if (recordsAmongEachDay) {
        setDailyCaloriesConsumption(getTotalCalories(recordsAmongEachDay));
        setDailyCarbsConsumption(getTotalCarbs(recordsAmongEachDay));
        setDailyProteinConsumption(getTotalProtein(recordsAmongEachDay));
        setDailyFatConsumption(getTotalFat(recordsAmongEachDay));
      }
      setData(
        weeklyCalorieConsumptionData(
          records,
          bmr,
          (0.55 * bmr) / 4.1,
          (0.2 * bmr) / 4.1,
          (0.25 * bmr) / 8.8
        )
      );
    }
  }, [records, foods]);

  useEffect(() => {
    if (metrics) {
      const dailyMetrics = groupBy(metrics, "date", (val) =>
        new Date(val).toLocaleDateString()
      );
      const todayMetrics = dailyMetrics[new Date().toLocaleDateString()];
      if (todayMetrics) {
        const weightMetric = todayMetrics.filter(({ weight }) => weight);
        if (weightMetric.length > 0) {
          setCurrentWeight(weightMetric[0].weight);
          setVisible(false);
        } else setVisible(true);
      } else {
        setVisible(true);
      }
      setWeightData(weeklyWeightData(metrics, goalWeight));
    }
  }, [metrics]);

  return (
    <>
      <Modal
        visible={visible}
        onClose={() => setVisible(false)}
        onSave={() => {
          if (currentWeightInput) {
            if (currentWeightInput > 0)
              onAddMetric(currentWeightInput) && setErrors({});
            else
              setErrors({
                "current-weight": "Weight must be a positive value",
              });
          }
        }}
        title="Current Weight"
        msg="Seems you didn't fill your weight today. Please fill it in the box below."
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="overflow-hidden sm:rounded-md">
            <div className="bg-white sm:p-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="current-weight"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Current Weight
                  </label>
                  <input
                    type="number"
                    name="current-weight"
                    id="current-weight"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    onChange={(e) =>
                      setCurrentWeightInput(e.target.valueAsNumber)
                    }
                  />
                  <InputError field="current-weight" errors={errors} />
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal>
      <Header name="Dashboard" />
      <div className="w-full p-4 my-3">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 sm:col-span-6 md:col-span-6">
            <StatCard
              title="Current Weight"
              description="deviation of current weight from goal weight"
              img={weightScale}
              statistic={currentWeight}
              unit="KG"
              deviationPercentage={deviationPercentage(
                currentWeight,
                goalWeight
              )}
            />
          </div>
          <div className="col-span-12 sm:col-span-6 md:col-span-6">
            <StatCard
              title="Daily Calories Consumption"
              description="deviation of daily calories consumption from recommended calorie intake"
              img={caloriesIntake}
              statistic={dailyCaloriesConsumption}
              unit="cal"
              deviationPercentage={deviationPercentage(
                dailyCaloriesConsumption,
                bmr
              )}
            />
          </div>

          <div className="col-span-12 sm:col-span-6 md:col-span-4">
            <StatCard
              title="Daily Carbs Consumption"
              description="deviation of daily carbs consumption from recommended calorie intake"
              img={carbs}
              statistic={dailyCarbsConsumption}
              unit="GM"
              deviationPercentage={deviationPercentage(
                dailyCarbsConsumption,
                (0.55 / 4.1) * bmr
              )}
            />
          </div>
          <div className="col-span-12 sm:col-span-6 md:col-span-4">
            <StatCard
              title="Daily Protein Consumption"
              description="deviation of daily protein consumption from recommended calorie intake"
              img={protein}
              statistic={dailyProteinConsumption}
              unit="GM"
              deviationPercentage={deviationPercentage(
                dailyProteinConsumption,
                (0.2 / 4.1) * bmr
              )}
            />
          </div>
          <div className="col-span-12 sm:col-span-6 md:col-span-4">
            <StatCard
              title="Daily Fat Consumption"
              description="deviation of daily fat consumption from recommended calorie intake"
              img={fat}
              statistic={dailyFatConsumption}
              unit="GM"
              deviationPercentage={deviationPercentage(
                dailyFatConsumption,
                (0.25 / 8.8) * bmr
              )}
            />
          </div>
        </div>
        <div className="grid grid-cols-12 gap-4 mt-8">
          <div className="col-span-12 xl:col-span-8 xl:col-start-3 xl:col-end-11 chart-container">
            <Line
              data={data}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  title: {
                    display: true,
                    text: "Latest Nutrition Consumption Records",
                    font: { size: "24px" },
                  },
                },
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-12 gap-4 mt-8">
          <div className="col-span-12 xl:col-span-8 xl:col-start-3 xl:col-end-11 chart-container">
            <Line
              data={weightData}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  title: {
                    display: true,
                    text: "Latest Weight Measurements",
                    font: { size: "24px" },
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
