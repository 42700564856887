import axios from "axios";

const instance = axios.create({});

instance.defaults.baseURL = "https://nutrition-tracker-api.netlify.app/.netlify/functions/serverless/api/v1";

instance.interceptors.request.use(
  (request) => {
    if (!request.headers.Authorization) {
      const token = localStorage.getItem("token");
      if (token) request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  },
  (err) => {
    console.error({ ...err });
    return Promise.reject(err);
  }
);

instance.interceptors.response.use((response) => {
  // console.log({ response });
  return response;
});

export default instance;
