import { useState } from "react";

const validation = {
  required: {
    value: Boolean,
    message: String,
  },
  pattern: {
    value: String,
    message: String,
  },
  custom: {
    isValid: (value = String) => Boolean,
    message: String,
  },
};

const useForm = (
  options = {
    validations: validation,
    initialValues: {},
    onSubmit: () => {},
  }
) => {
  const [data, setData] = useState(options?.initialValues || {});
  const [errors, setErrors] = useState({});

  // Needs to extend unknown so we can add a generic to an arrow function
  const handleChange = (key, transformFN) => (e) => {
    setErrors((previous) => {
      const newErros = { ...previous };
      delete newErros[key];
      return newErros;
    });
    const value = transformFN ? transformFN(e.target.value) : e.target.value;
    setData({
      ...data,
      [key]: value,
    });
  };

  const validData = () => {
    const validations = options?.validations;

    if (validations) {
      let valid = true;
      const newErrors = {};
      for (const key in validations) {
        const value = data[key];
        const validation = validations[key];
        if (validation?.required?.value && !value && value !== 0) {
          valid = false;
          newErrors[key] = validation?.required?.message;
          continue;
        }

        const pattern = validation?.pattern;
        if (pattern?.value && !RegExp(pattern.value).test(value)) {
          valid = false;
          newErrors[key] = pattern.message;
          continue;
        }

        const custom = validation?.custom;
        if (custom?.isValid && !custom.isValid(value)) {
          valid = false;
          newErrors[key] = custom.message;
        }
      }

      if (!valid) {
        setErrors(newErrors);
        return false;
      }
    }

    setErrors({});
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validData()) {
      return;
    }

    if (options?.onSubmit) {
      options.onSubmit();
    }
  };

  return {
    data,
    handleChange,
    handleSubmit,
    errors,
  };
};

export default useForm;
