import React, { useCallback, useContext, useState } from "react";
import api from "../api/api";
import SpinnerContext from "./spinnerContext";

const AuthContext = React.createContext({
  isLoggedIn: false,
  login: (email, password) => {},
  logout: () => {},
});

export const AuthContextProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(!!localStorage.getItem("token"));
  const spinner = useContext(SpinnerContext);

  const login = async (email, password) => {
    try {
      spinner.show();
      const data = await api.login({ email, password });
      const { token, user } = data.data;
      if (token && user) {
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));
        setLoggedIn(true);
      }
    } catch (error) {
    } finally {
      spinner.hide();
    }
  };

  const logout = useCallback(() => {
    localStorage.removeItem("token");
    setLoggedIn(false);
  }, []);

  const context = { isLoggedIn: loggedIn, login, logout };

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

export default AuthContext;
