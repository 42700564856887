import { useEffect, useState } from "react";

const DOTS = "...";

const range = (start, end) => {
  let length = end - start + 1;
  // {length:8} fills array with 8 undefind, next is the filler
  return Array.from({ length }, (_, idx) => idx + start);
};

const usePagination = (totalCount, currentPage, pageSize) => {
  const [paginationRanges, setPaginationRanges] = useState([]);

  const totalPageCount = Math.ceil(totalCount / pageSize);
  const firstPageIndex = 1;
  const lastPageIndex = totalPageCount;

  useEffect(() => {
    const siblingCount = 2;

    // Pages count is determined as: start + end + 2 * sibling + current
    const totalPageNumbers = 2 * siblingCount + 3;

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    if (totalPageNumbers >= totalPageCount) {
      setPaginationRanges(range(1, totalPageCount));
    } else if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);
      setPaginationRanges([...leftRange, DOTS, totalPageCount]);
    } else if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      setPaginationRanges([firstPageIndex, DOTS, ...rightRange]);
    } else if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      setPaginationRanges([
        firstPageIndex,
        DOTS,
        ...middleRange,
        DOTS,
        lastPageIndex,
      ]);
    }
  }, [currentPage, totalPageCount, lastPageIndex]);

  return { paginationRanges, totalPageCount, firstPageIndex, lastPageIndex };
};

export default usePagination;
