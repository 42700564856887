import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import compress from "../api/compress";
import Header from "../components/Header";
import Input from "../components/Input";
import { foodForm } from "../utilities/forms";
import SpinnerContext from "../contexts/spinnerContext";
import useFetch from "../hooks/useFetch";
import useForm from "../hooks/useForm";

const LABEL_CLASSES = "capitalize block text-sm font-medium text-gray-700";

const FoodForm = ({ onFoodAdded, onFoodUpdate }) => {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const operation = search.get("operation");
  const [file, setFile] = useState();
  let initialValues = location.state;
  const spinnerContext = useContext(SpinnerContext);

  const whileFetching = () => {
    spinnerContext.show();
  };

  const whenFinished = (message, status) => {
    spinnerContext.hide();
  };

  const { response, sendData } = useFetch(whileFetching, whenFinished);

  useEffect(() => {
    if (response) {
      if (operation === "add") onFoodAdded(response);
      else if (operation === "update") onFoodUpdate(response);
    }
  }, [response]);

  const handleFileChange = (e) => {
    const { files } = e.target;
    if (files.length > 0) {
      compress(files[0], (compressedFile) => {
        setFile(compressedFile);
      });
    }
  };

  const {
    handleSubmit,
    handleChange,
    data: food,
    errors,
  } = useForm({
    validations: foodForm.validations,
    initialValues: initialValues || foodForm.initialValues,
    onSubmit: () => {
      const formData = new FormData();
      for (const key in food) {
        formData.append(key, food[key]);
      }
      if (file) formData.append("image", file);
      if (operation === "add")
        sendData("/users/loggedIn/foods", "POST", formData, {
          "Content-Type": "multipart/form-data",
        });
      else if (operation === "update" && location.state?._id) {
        sendData(
          "/users/loggedIn/foods/" + location.state._id,
          "PUT",
          formData,
          {
            "Content-Type": "multipart/form-data",
          }
        );
      }
    },
  });

  return (
    <>
      <Header name={`${operation} Food`} />
      <div className="mt-0 md:my-10">
        <div className="md:grid md:grid-cols-6 md:gap-6">
          <div className="md:col-span-1" />
          <div className="mt-5 md:mt-0 md:col-span-4">
            <form onSubmit={handleSubmit}>
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6" id="form-inputs">
                    {Object.entries(foodForm.inputs).map(
                      ([field, config], index) => (
                        <Input
                          key={field}
                          field={field}
                          type={config.type}
                          data={food}
                          errors={errors}
                          onChange={handleChange(field, config.transformFN)}
                          focused={index === 0}
                        />
                      )
                    )}
                    <div className="col-span-6">
                      <label className={LABEL_CLASSES}>Photo</label>
                      <div className="mt-1 flex items-center">
                        <input
                          type="file"
                          onChange={handleFileChange}
                          accept="image/*"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button
                    className="capitalize inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    id="submit-button"
                    type="submit"
                  >
                    {operation}
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="md:col-span-1" />
        </div>
      </div>
    </>
  );
};

export default FoodForm;
